body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    /* min-width: 700px; */
    background-color: #b6b6b6;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background-color: #dfdfdf;
}

::-webkit-scrollbar-thumb {
    background-color: #b6b6b6;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #969696;
}

.loader {
    width: 30px;
    height: 30px;
    border: 3px solid #a81e1a;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader2 {
    width: 180px;
    height: 140px;
    display: block;
    margin: 0 auto 20px;
    background-image: radial-gradient(
            circle 25px at 25px 25px,
            #fff 100%,
            transparent 0
        ),
        radial-gradient(circle 50px at 50px 50px, #fff 100%, transparent 0),
        radial-gradient(circle 25px at 25px 25px, #fff 100%, transparent 0),
        radial-gradient(circle 15px at 15px 15px, #fff 100%, transparent 0),
        linear-gradient(#fff 50px, transparent 0);
    background-size: 50px 50px, 100px 75px, 50px 50px, 30px 32px, 136px 20px;
    background-repeat: no-repeat;
    background-position: 0px 30px, 30px 0px, 113px 29px, 147px 50px, 23px 60px;
    position: relative;
    box-sizing: border-box;
}
.loader2::after {
    content: '';
    position: absolute;
    left: 2px;
    top: 65px;
    width: 2px;
    height: 6px;
    color: #fff;
    box-sizing: border-box;
    animation: animloader 0.6s linear infinite;
}

@keyframes animloader {
    0% {
        box-shadow: 25px 0 white, 50px 0 white, 75px 0 white, 100px 0 white,
            125px 0 white, 150px 0 white, 25px 0 white, 50px 0 white,
            75px 0 white, 100px 0 white, 125px 0 white, 150px 0 white;
    }
    50% {
        box-shadow: 25px 20px white, 50px 60px rgba(255, 255, 255, 0),
            75px 30px rgba(255, 255, 255, 0), 100px 70px rgba(255, 255, 255, 0),
            125px 40px white, 150px 60px rgba(255, 255, 255, 0), 25px 20px white,
            50px 30px white, 75px 10px white, 100px 30px white,
            125px 30px rgba(255, 255, 255, 0), 150px 30px rgba(255, 255, 255, 0);
    }
    100% {
        box-shadow: 25px 60px rgba(255, 255, 255, 0),
            50px 60px rgba(255, 255, 255, 0), 75px 50px rgba(255, 255, 255, 0),
            100px 70px rgba(255, 255, 255, 0), 125px 70px rgba(255, 255, 255, 0),
            150px 60px rgba(255, 255, 255, 0), 25px 80px rgba(255, 255, 255, 0),
            50px 80px rgba(255, 255, 255, 0), 75px 70px rgba(255, 255, 255, 0),
            100px 60px rgba(255, 255, 255, 0), 125px 30px rgba(255, 255, 255, 0),
            150px 30px rgba(255, 255, 255, 0);
    }
}
.wrapper {
    text-align: center;
    h1 {
        color: #fff;
        font-size: 42px;
        text-transform: uppercase;
        font-weight: 700;
        font-family: 'Josefin Sans', sans-serif;
        background: linear-gradient(
            to right,
            #c51a1a 60%,
            #a8b5bb 50%,
            #ffffff 10%
        );
        background-size: auto auto;
        background-clip: border-box;
        background-size: 200% auto;
        color: #fff;
        background-clip: text;
        /* text-fill-color: transparent; */
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: textclip 1.5s linear infinite;
        display: inline-block;
    }
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}
